import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ChangeEvent, useState } from 'react';

import { useLandingContext } from 'holded/lib/context/landingContext';
import { NewsletterFooterSection } from 'holded/modules/cms/domain/components/newsletter';

const Modal2 = dynamic(() => import('holded/modules/cms/ui/shared/components/ModalMessage'));
const Button = dynamic(() => import('holded/modules/cms/ui/shared/components/Button'));

const emailPatternTest = new RegExp(
  /^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,12}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i,
);

const NewsletterFooter = ({ title, description, input, message, hubspot }: NewsletterFooterSection) => {
  const { pageData } = useLandingContext();
  const [messageText, setMessageText] = useState('');
  const [email, setEmail] = useState('');
  const router = useRouter();

  function isEmailValid() {
    if (email.trim().length === 0) return alert('Añade un correo');

    if (!emailPatternTest.test(email)) return alert('Correo incorrecto');

    return true;
  }

  const submitHubSpot = async () => {
    if (!isEmailValid()) return;

    const xhr = new XMLHttpRequest();
    const shortName = pageData.attributes?.shortName;
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspot.portalId}/${hubspot.formId}`;
    const pageUri = process.env.NEXT_PUBLIC_URL + router.asPath;

    const date = new Date();
    const dataForm = {
      submittedAt: date.getTime(),
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: pageUri ?? '',
        pageName: shortName ?? '',
        sfdcCampaignId: hubspot?.sfdcCampaignId ?? '',
      },
    };

    const finalData = JSON.stringify(dataForm);

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'json';

    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setMessageText('ok');
        setEmail('');
      }

      if ((xhr.status == 400 || xhr.status == 403 || xhr.status == 404) && xhr.readyState == 4) {
        alert(xhr.response.errors[0].message);
      }
    };

    xhr.send(finalData);
  };

  const handlerEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <div className={'w-full flex flex-col justify-start '}>
        <p className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{title}</p>
        <p className="mt-2 text-base text-gray-300">{description}</p>
      </div>
      <div className={`w-full sm:w-2/3 h-full mt-5 lg:mt-0 flex justify-end flex-col sm:flex-row gap-4 sn:gap-4 `}>
        {hubspot && (
          <>
            <input
              className={'rounded sm:mr-5 md:w-80'}
              type={'email'}
              placeholder={input?.placeholder ?? ''}
              value={email}
              onInput={handlerEmail}
            />
            <Button
              data={{
                idTag: 'newsletter-hs-footer',
                type: 'primary',
                action: 'cb',
                text: input?.submit,
                defaultButton: false,
              }}
              cb={submitHubSpot}
            >
              {input?.submit}
            </Button>
            {!!messageText && <Modal2 closeModal={() => setMessageText('')} isOpen={!!messageText} message={message} />}
          </>
        )}
      </div>
    </>
  );
};

export default NewsletterFooter;
